<script>
import Modal from './Modal.vue';

export default {
  name: 'NPTerms',
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: {
    Modal
  }
};
</script>

<template>
  <Modal @close="close">
    <span slot="header">Terms & Conditions</span>
    <span slot="body">
      <div id="NP_terms">
        <p>
          Minimum requested loan amount must be $150,000.00 or such greater amount as may be prescribed from time to
          time pursuant to
          12 C.F.R. Section 1026.3(b) of Regulation Z issued by the Consumer Financial Protection Bureau as the minimum
          threshold
          necessary to qualify the Line of Credit as an exempt consumer credit transaction thereunder.
          The maximum loan amount is dependent on the Bank’s legal lending limit, per borrower.
        </p>
        <ol>
          <li>
            Securities-based borrowing has special risks and may not be suitable for everyone.
            Consult a financial advisor about any associated risks.
            Consult a tax advisor for tax-related matters and an attorney for legal matters,
            42 North Private Bank (“Bank”) does not provide financial, tax or legal advice.
          </li>
          <li>
            Product suitability is determined through income, net worth, and liquid asset questions.
          </li>
          <li>
            Subject to credit approval and underwriting. Approval requirements include a minimum FICO score of 680,
            no foreclosures within the last 2 years, and no bankruptcies within the last year.
          </li>
          <li>
            Advance rates are based on collateral types: Equity, Fixed Income, Funds/ETF,
            and collateral sub types. Depending on the collateral sub type, advance rates range from 20-95%.
          </li>
        </ol>
      </div>
    </span>
  </Modal>
</template>

<style>
#NP_terms {
  font-size: 15px;
  line-height: 1.4;
  font-style: italic;
}

#NP_terms ol li:not(:last-child) {
  margin-bottom: 16px;
}
</style>