<template>

    <div id="home" class="wealthlending">

      <header style="background-color:#ffffff;">
          <!-- add 'sticky' to the class='header' to add the 'sticky' styles -->
          <div class="header-white">
              <div class="header__logo">
                  <a href="/">
                      <!-- eslint-disable-next-line vue/no-parsing-error -->
                      <svg width="184px" height="37px" viewBox="0 0 184 37" version="1.1" xmlns="https://urldefense.com/v3/__https://www.w3.org/2000/svg__;!!Ebr-cpPeAnfNniQ8HSAI-g_K5b7VKg!arviJSoTQN27mpd5C6_RRWO955hYmigfJeovf4UjCqsixW3TTuyyyy-u4nnutDpY-O3S_hKIMQ$ " xmlns:xlink="https://www.w3.org/1999/xlink">
                          <!-- Generator: Sketch 43 (38999) - https://urldefense.com/v3/__http://www.bohemiancoding.com/sketch__;!!Ebr-cpPeAnfNniQ8HSAI-g_K5b7VKg!arviJSoTQN27mpd5C6_RRWO955hYmigfJeovf4UjCqsixW3TTuyyyy-u4nnutDpY-O3w6HWJVA$  -->
                          <desc>Created with Sketch.</desc>
                          <defs></defs>
                          <g id="PageLogo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="BRLogo" fill-rule="nonzero" fill="#00578E">
                                  <g id="GroupLogo1" transform="translate(46.000000, 7.000000)">
                                      <path
                                          d="M6.5,21.8 L0.1,21.8 L0.1,2.3 L6.6,2.3 C10.2,2.3 13.3,3.5 13.3,7.2 C13.3,9.7 12,11.1 9.8,11.7 L9.8,11.8 C12.1,12.2 14.1,13.7 14.1,16.5 C14.2,20.4 11,21.8 6.5,21.8 M6.2,5.4 L3.8,5.4 L3.8,10.3 L6.1,10.3 C8.3,10.3 9.3,9.5 9.3,7.8 C9.3,6.2 8.2,5.4 6.2,5.4 M6.1,13.3 L3.7,13.3 L3.7,18.7 L5.9,18.7 C8.3,18.7 9.5,18 9.5,16.1 C9.6,14.2 8.5,13.3 6.1,13.3"
                                          id="ShapeLogo1"></path>
                                      <path
                                          d="M24.8,11 C24.6,10.9 24.1,10.7 23.3,10.7 C21.6,10.7 20.5,12.2 20.2,12.9 L20.2,21.9 L16.3,21.9 L16.3,7.5 L20.2,7.5 L20.2,9.4 C20.8,8.3 22,7.1 23.8,7.1 C24.9,7.1 25.4,7.3 25.7,7.5 L24.8,11 Z"
                                          id="ShapeLogo2"></path>
                                      <path
                                          d="M32.7,22.2 C27.7,22.2 25.5,18.4 25.5,14.8 C25.5,11.1 27.6,7.3 32.6,7.3 C37.6,7.3 39.8,11 39.8,14.7 C39.9,18.2 37.7,22.2 32.7,22.2 M32.6,10.1 C30.4,10.1 29.7,12 29.7,14.6 C29.7,17.2 30.6,19.2 32.8,19.2 C35,19.2 35.8,17.4 35.8,14.7 C35.8,12 34.8,10.1 32.6,10.1"
                                          id="ShapeLogo3"></path>
                                      <path
                                          d="M49.5,21.8 C49.4,21.4 49.3,20.7 49.3,20.3 C48.6,21.1 47.3,22.2 45.3,22.2 C42.9,22.2 41,20.7 41,18.2 C41,14.5 44.8,13.1 48.6,13.1 L49.1,13.1 L49.1,12.3 C49.1,11.1 48.6,10.3 46.8,10.3 C44.9,10.3 43.7,11.3 43.2,11.6 L41.5,9.2 C42.3,8.4 44.2,7.2 47.3,7.2 C51.2,7.2 53,8.7 53,12.5 L53,17.6 C53,19.5 53.2,20.9 53.4,21.8 L49.5,21.8 L49.5,21.8 Z M48.7,15.5 L48.2,15.5 C45.9,15.5 44.3,16.2 44.3,17.9 C44.3,19 45.1,19.4 46,19.4 C47.4,19.4 48.2,18.6 48.7,18.1 L48.7,15.5 Z"
                                          id="ShapeLogo4"></path>
                                      <path
                                          d="M61.3,7.2 C63,7.2 64.1,7.8 64.6,8.2 L64.6,0.8 L68.5,0.8 L68.5,17.7 C68.5,19.6 68.7,21 68.9,21.8 L65,21.8 C64.9,21.4 64.7,20.7 64.7,20.2 C63.8,21.2 62.6,22.1 60.7,22.1 C57.1,22.1 54.8,19.4 54.8,14.6 C55,10.1 57.7,7.2 61.3,7.2 M62,19 C63.4,19 64.4,18 64.7,17.6 L64.7,11.2 C64.2,10.8 63.5,10.3 62.2,10.3 C60.2,10.3 59.1,12 59.1,14.8 C59.1,17.4 60,19 62,19"
                                          id="ShapeLogo5"></path>
                                      <path
                                          d="M80,11 C79.8,10.9 79.3,10.7 78.5,10.7 C76.8,10.7 75.7,12.2 75.4,12.9 L75.4,21.9 L71.5,21.9 L71.5,7.5 L75.4,7.5 L75.4,9.4 C76,8.3 77.2,7.1 79,7.1 C80.1,7.1 80.6,7.3 80.9,7.5 L80,11 Z"
                                          id="ShapeLogo6"></path>
                                      <path
                                          d="M84.4,5.7 C83.1,5.7 82.2,4.7 82.2,3.5 C82.2,2.4 83.1,1.4 84.4,1.4 C85.7,1.4 86.6,2.4 86.6,3.5 C86.6,4.7 85.7,5.7 84.4,5.7"
                                          id="ShapeLogo7"></path>
                                      <rect id="RectanglepathLogo" x="82.4" y="7.5" width="3.9" height="14.3">
                                      </rect>
                                      <path
                                          d="M94.9,7.2 C96.6,7.2 97.7,7.8 98.2,8.2 L98.2,0.8 L102.1,0.8 L102.1,17.7 C102.1,19.6 102.3,21 102.5,21.8 L98.6,21.8 C98.5,21.4 98.3,20.7 98.3,20.2 C97.4,21.2 96.2,22.1 94.3,22.1 C90.7,22.1 88.4,19.4 88.4,14.6 C88.5,10.1 91.3,7.2 94.9,7.2 M95.6,19 C97,19 98,18 98.3,17.6 L98.3,11.2 C97.8,10.8 97.1,10.3 95.8,10.3 C93.8,10.3 92.7,12 92.7,14.8 C92.7,17.4 93.6,19 95.6,19"
                                          id="ShapeLogo8"></path>
                                      <path
                                          d="M118.2,7.2 L118.2,10.1 L116.3,10.1 C116.7,10.8 116.8,11.6 116.8,12.4 C116.8,15.5 114.6,17.6 110.7,17.6 C110.1,17.6 109.5,17.5 109,17.5 C108.8,17.7 108.5,17.9 108.5,18.3 C108.5,19.1 109.3,19.2 111.4,19.3 C114.4,19.4 118.1,19.5 118.1,23.7 C118.1,26.7 115,28.9 110.3,28.9 C106.4,28.9 104,27.7 104,25.1 C104,23.5 105,22.4 106.4,21.6 C105.6,21.1 105.1,20.5 105.1,19.4 C105.1,18.1 105.9,17.2 106.7,16.7 C105.1,15.8 104.2,14.3 104.2,12.4 C104.2,9.3 106.6,7.2 110.5,7.2 L118.2,7.2 M107.3,24.3 C107.3,25.4 108.1,26.2 110.1,26.2 C112.5,26.2 113.8,25.3 113.8,24.1 C113.8,22.6 112.5,22.6 110.4,22.5 C109.7,22.4 109.1,22.4 108.5,22.3 C107.7,22.8 107.3,23.4 107.3,24.3 M113.1,12.4 C113.1,10.7 112.1,9.7 110.5,9.7 C108.9,9.7 107.9,10.7 107.9,12.4 C107.9,14 108.9,15.1 110.5,15.1 C112.1,15 113.1,14 113.1,12.4"
                                          id="ShapeLogo9"></path>
                                      <path
                                          d="M126.7,19.3 C128.6,19.3 129.7,18.6 130.4,18.3 L131.7,20.8 C130.8,21.3 129.3,22.2 126.4,22.2 C121.6,22.2 118.9,19.2 118.9,14.6 C118.9,10.1 122,7.2 126,7.2 C130.5,7.2 132.6,10.3 132.1,15.6 L123,15.6 C123.2,17.8 124.3,19.3 126.7,19.3 L126.7,19.3 Z M128.4,12.9 C128.4,11.3 127.7,10 125.9,10 C124.3,10 123.3,11.1 123.1,12.9 L128.4,12.9 Z"
                                          id="ShapeLogo10"></path>
                                      <path
                                          d="M135.4,11.2 C134.3,11.2 133.4,10.3 133.4,9.2 C133.4,8.1 134.3,7.2 135.4,7.2 C136.5,7.2 137.4,8.1 137.4,9.2 C137.4,10.3 136.5,11.2 135.4,11.2 L135.4,11.2 Z M135.4,7.5 C134.4,7.5 133.7,8.2 133.7,9.2 C133.7,10.2 134.4,10.9 135.4,10.9 C136.4,10.9 137.1,10.2 137.1,9.2 C137.1,8.2 136.4,7.5 135.4,7.5 L135.4,7.5 Z M136,10.4 L135.5,9.6 C135.4,9.4 135.3,9.4 135.1,9.4 L135.1,10.5 L134.7,10.5 L134.7,8 L135.5,8 C136,8 136.3,8.3 136.3,8.7 C136.3,9.1 136.1,9.3 135.7,9.3 C135.8,9.4 135.9,9.5 136,9.6 L136.5,10.4 L136,10.4 L136,10.4 Z M135.4,8.3 L135.1,8.3 L135.1,9 L135.3,9 C135.6,9 135.8,9 135.8,8.6 C135.8,8.5 135.7,8.3 135.4,8.3 L135.4,8.3 Z"
                                          id="ShapeLogo11"></path>
                                  </g>
                                  <g id="GroupLogo2">
                                      <polygon id="ShapeLogo12" points="18.4 19.4 18.5 19.4 18.5 19.4"></polygon>
                                      <path
                                          d="M33.7,0.1 L33.7,0.1 L0.8,0 L0.8,0.1 L0.8,37 L33.7,37 L33.7,0.1 L33.7,0.1 Z M26.5,25.3 L26.5,26.9 C22.9,26.9 20.6,25.8 19.2,24.3 C19.4,25 19.7,25.6 20,26.3 C22.5,31.1 26.8,33.6 33,33.7 L33,35.7 C24.2,35.6 20,31 18.1,27.1 C17.7,26.3 17.3,25.4 17.1,24.6 C14.2,31.8 6.2,31.9 6.1,31.9 L6.1,29.7 C6.5,29.7 15.9,29.4 15.9,18.6 L18.3,18.6 C18.3,7.8 27.7,7.7 28.1,7.7 L28.1,5.5 C28,5.5 20,5.6 17.1,12.8 C16.8,12 16.5,11.1 16.1,10.3 C14.2,6.4 10,1.8 1.2,1.7 L1.2,0.7 L33,0.7 L33,18.7 L18.5,18.7 C18.4,18.8 18.6,25.3 26.5,25.3 Z M1.3,3.6 C7.6,3.7 11.9,6.2 14.3,11 C14.7,11.7 14.9,12.4 15.1,13 C13.7,11.6 11.4,10.4 7.8,10.4 L7.8,12 C15.8,12 15.9,18.2 15.9,18.5 L1.3,18.5 L1.3,3.6 Z"
                                          id="ShapeLogo13"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                  </a>
              </div>
          </div>

      </header>

      <!--Wealth Lending Network Code-->

      <section class="hero">

          <div class="outer-wrapper">
              <div class="inner-wrapper">
                  <div class="hero__content" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                      <div class="hero__content-wrapper">
                          <h2 class="hero__name large">Wealth Lending Network</h2>
                          <h1 class="hero__title">Connect clients to liquidity<br>without adding overhead</h1>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="description">
          <div class="outer-wrapper">
              <div class="description__content">
                  <div class="inner-wrapper">
                      <div class="deepen-copy"  style="padding-bottom: 48px;">
                          <h3 class="heading-big left">Deepen relationships. Safeguard AUM.<br />Attract new assets.</h3>
                          <p class="hero__info">
                            Provide financial flexibility to your clients — even if you don't have a relationship with
                            a bank, or the systems to manage risk. Now independent broker dealers, financial advisors
                            and RIAs can utilize the Broadridge Wealth Lending Network to quickly present clients with
                            cost-effective, right-fit lending options.
                          </p>
                          <p class="hero__info">
                            Security based lending (SBL) enables your clients to access an interest-only line of
                            credit based on 50-95% of the value of eligible securities in their portfolio.<sup><a href="#1" class="sup-links">1</a></sup>
                          </p>
                          <p class="hero__info">
                            Another liquidity option is the Insurance-Backed Line of Credit (IBLOC),
                            an interest-only credit line which can provide your clients with up to 95% of the
                            value of eligible whole life insurance policies at competitive rates and terms.<sup><a href="#2" class="sup-links">2 - 4</a></sup>
                          </p>
                          <p class="hero__info">
                            Add value to your client services by offering lower cost funding with
                            no added overhead for technology and no credit risk or capitalisation. Get started today!
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section id="lending-form" class="contact-broadridge">
          <div class="outer-wrapper form-container" id="lendingform">
              <div class="inner-wrapper individual-link">
                <a :href="LINK_BR_SBL_OPTIMIZER" target="_blank">Click here to learn more about Securities Backed Lending</a>
              </div>
              <div class="inner-wrapper">
                  <h3 class="heading-big contact-broadridge__form__title"><span>My client is looking for lower cost
                          liquidity.</span></h3>
               </div>
                <div class="inner-wrapper">
                    <div id="contact-info" class="deepen-copy" style="color: #00578E;">
                        <p>Please contact wealthlendingnetwork@broadridge.com to find out more.</p>
                    </div>
                </div>
              <div class="inner-wrapper noflex">
                  <div class="lending-broadridge__form-wrapper">
                      <div class="contact-broadridge__form">
                          <form @submit.prevent="submitForm()">
                              <div v-if="submissionError" class="errors">
                                  <p>There was an error submitting your request. Please check your entry and try again.</p>
                                  <ul v-if="formErrors">
                                      <li v-for="(err, index) in formErrors" :key="index">{{ err }}</li>
                                  </ul>
                              </div>

                              <div style="margin-bottom: -20px; font-weight: bold;"><p>All fields are required.</p></div>

                            <div class="form__field"><input required="required" name="financial_professional_name"
                                                            class="form__field__input form__field__input--text"
                                                            aria-label="Advisor Name" type="text"
                                                            placeholder="Advisor Name" v-model="formSubmission.FinancialProfessionalName">
                              <span class="tooltiptext">Enter the advisor’s first name and surname</span>
                            </div>
                            <div class="form__field"><input required="required" name="firm"
                                                            class="form__field__input form__field__input--text" aria-label="Firm" type="text"
                                                            placeholder="Firm" v-model="formSubmission.Firm">
                              <span class="tooltiptext">Enter the name of the firm</span>
                            </div>
                            <div class="form__field"><input required="required" name="email"
                                                            class="form__field__input form__field__input--text" aria-label="Advisor Email" type="email"
                                                            placeholder="Advisor Email" v-model="formSubmission.Email">
                              <span class="tooltiptext">Enter the advisor's email address</span>
                            </div>
                            <div class="form__group">
                              <div class="form__field"><input required="required" name="rep_code"
                                                              class="form__field__input form__field__input--not-empty form__field__input--text"
                                                              aria-label="Rep Code" type="text" placeholder="Rep Code" v-model="formSubmission.RepCode">
                                <span class="tooltiptext">Enter the advisor's rep code</span>
                              </div>
                              <div class="form__field"><input required="required" name="phone"
                                                              class="form__field__input form__field__input--text" aria-label="Phone"
                                                              type="text" placeholder="Phone" v-model="formSubmission.PhoneNumber">
                                <span class="tooltiptext">Enter the advisor’s phone number</span>
                              </div>
                            </div>
                            <div class="form__field"><input required="required" name="borrow_name"
                                                            class="form__field__input form__field__input--text" aria-label="Borrow Name"
                                                            type="text" placeholder="Borrower Name" v-model="formSubmission.BorrowerName">
                              <span class="tooltiptext">Enter the primary borrower’s first name and surname</span>
                            </div>
                            <div class="form__field"><input required="required" name="loan_amount"
                                                            class="form__field__input form__field__input--text" aria-label="Loan Amount"
                                                            type="number" placeholder="Loan Amount" v-model="formSubmission.LoanAmount">
                              <span class="tooltiptext">Enter the estimated loan amount</span>
                            </div>



                            <div class="form__field">
                              <span class="tooltiptext">Select the custodian of the client’s accounts from the list or if not listed specify in Other</span>
                             <div>
                              <label style="display:block">Custodian:</label>
                             </div>
                             <div v-for="option in OPTIONS" v-bind:key="option.custodian">
                                <input name="custodian"
                                       :id="`${option.custodian.replace(/ /g, '')}_radio`"
                                       class="form__field__input form__field__input--radiobutton"
                                       style="margin-bottom:20px;"
                                       type="radio" v-model="formSubmission.Custodian" :value="option.custodian">
                                <label :for="`${option.custodian.replace(/ /g, '')}_radio`">{{option.custodian}}</label>
                              </div>

                              <input name="custodian" id="other_radio"
                                       class="form__field__input form__field__input--radiobutton"
                                       type="radio" v-model="formSubmission.Custodian" :value="CUSTODIANS.OTHER">
                              <label for="other_radio">Other (Please specify)</label>
                              <div id="other_custodian_text">
                                <input :disabled="formSubmission.Custodian !== CUSTODIANS.OTHER" required="required" name="custodian"
                                       class="form__field__input form__field__input--text" aria-label="Other Custodian"
                                       type="text" placeholder="Other Custodian" ref="custodian-other" v-model="formSubmission.CustodianOtherText">
                              </div>
                            </div>

                            <div v-if="custodiansWithMultipleLenderBanks.includes(formSubmission.Custodian)"
                                 class="form__field"
                                 id="choose_lender_bank">
                              <label style="display:block">Choose a Lender Bank:</label>
                              <div>
                                <input name="lender_bank"
                                       id="42np_radio"
                                       class="form__field__input form__field__input--radiobutton"
                                       style="margin-bottom:15px;"
                                       type="radio"
                                       v-model="formSubmission.LenderBank"
                                       :value="LENDER_BANKS.NORTH_PRIVATE_BANK"
                                       required="required"
                                       :disabled="!custodiansWithMultipleLenderBanks.includes(formSubmission.Custodian)">
                                <label for="42np_radio">42 North Private Bank</label>
                              </div>
                              <div>
                                  <input name="lender_bank"
                                         id="tbbk_radio"
                                         class="form__field__input form__field__input--radiobutton"
                                         style="margin-bottom:15px;"
                                         type="radio"
                                         v-model="formSubmission.LenderBank"
                                         :value="LENDER_BANKS.THE_BANCORP"
                                         required="required"
                                         :disabled="!custodiansWithMultipleLenderBanks.includes(formSubmission.Custodian)">
                                  <label for="tbbk_radio">The Bancorp</label>
                              </div>
                            </div>
                            <div v-if="OPTIONS.some(o => o.lenderBanks.length > 1 ? formSubmission.Custodian === o.custodian && o.lenderBanks.includes(formSubmission.LenderBank) : formSubmission.Custodian === o.custodian)" class="form__field">
                              <input id="terms_confirmation" type="checkbox" v-model="termsConfirmation" :required="OPTIONS.map(o => o.custodian).includes(formSubmission.Custodian)" />
                                <label for="terms_confirmation">
                                  I accept
                                </label>
                                <a @click="showModal = true">Terms & Conditions</a>
                              <TBBKTerms v-if="showModal && OPTIONS.some(o => formSubmission.Custodian === o.custodian && o.lenderBanks.includes(LENDER_BANKS.THE_BANCORP)) && (custodiansWithMultipleLenderBanks.includes(formSubmission.Custodian) ? formSubmission.LenderBank === LENDER_BANKS.THE_BANCORP : true)"
                                         @close="showModal = false">
                              </TBBKTerms>
                              <NPTerms v-if="showModal && OPTIONS.some(o => formSubmission.Custodian === o.custodian && o.lenderBanks.includes(LENDER_BANKS.NORTH_PRIVATE_BANK)) && (custodiansWithMultipleLenderBanks.includes(formSubmission.Custodian) ? formSubmission.LenderBank === LENDER_BANKS.NORTH_PRIVATE_BANK : true)"
                                         @close="showModal = false">
                              </NPTerms>
                            </div>

                            <div class="form__field">
                                <input id="form_confirmation" type="checkbox" v-model="formConfirmation" />
                                <label for="form_confirmation" v-if="OPTIONS.some(o => formSubmission.LenderBank === LENDER_BANKS.THE_BANCORP && o.lenderBanks.includes(LENDER_BANKS.THE_BANCORP) && o.custodian === formSubmission.Custodian)">
                                  I would like a representative of the Bank to contact me to discuss arranging a Securities-Based Loan or an Insurance-Backed Loan for my client
                                </label>
                                <label for="form_confirmation" v-else>
                                  I would like a representative of the Bank to contact me to discuss arranging a Securities-Based Loan for my client
                                </label>
                            </div>
                              <div class="form__field">
                                  <button v-if="!isLoading" :disabled="!formConfirmation" :style="!formConfirmation ? 'opacity: 0.6' : ''" class="form__field__input form__field__input--button cta"
                                      id="talk-to-us__submitCSI" aria-label="Contact Sales"
                                      type="submit">GO</button>

                                    <img class="spinner" alt="Submitting your request. Please wait" v-if="isLoading" src="../assets/images/spinner.gif"/>



                              </div>
                          </form>
                          <div class="individual-link">
                            <a :href="LINK_FEATURE_BANK_BANCORP" target="_blank">Click here to learn more about our feature partner banks</a>
                          </div>
                      </div>
                  </div>
                  <div class="lendingform__image">
                      <img class="show-large" src="../assets/images/ContactForm_visual_470x552.jpg"
                          alt="contact form image">
                  </div>
              </div>
            <img class="hide-large" src="../assets/images/ContactForm_visual_470x552.jpg"
                 alt="contact form image img-large">
          </div>
      </section>

      <section class="cards" id="card">
          <div class="outer-wrapper">
              <div class="inner-wrapper">
                  <div class="cards__container ">
                      <a href="#" target="_self" class="cards__card">
                          <div class="cards__preview has_image ">
                              <img src="../assets/images/ContentCard_Left_Article_372x255.jpg"
                                  title="How Digital SBL Offers a Roadmap to Post-COVID Liquidity"
                                  alt="How Digital SBL Offers a Roadmap to Post-COVID Liquidity"
                                  class="cards__image">
                          </div>
                          <div class="cards__copy">
                              <div class="cards__type">Article</div>
                              <div class="cards__title large">How Digital SBL Offers a Roadmap to Post-COVID Liquidity</div>
                          </div>
                      </a>

                      <a href="#" target="_self"
                          class="cards__card ">
                          <div class="cards__preview has_image ">
                              <img src="../assets/images/ContentCard_Mid_Report_372x255.jpg"
                                  title="Market insights for your Securities-Based Lending Strategy"
                                  alt="Market insights for your Securities-Based Lending Strategy"
                                  class="cards__image">
                          </div>
                          <div class="cards__copy">
                              <div class="cards__type">Webinar</div>
                              <div class="cards__title large">Market insights for your Securities-Based Lending Strategy</div>
                          </div>
                      </a>

                      <a href="#" target="_self" class="cards__card">
                          <div class="cards__preview has_image ">
                              <img src="../assets/images/ContentCard_Right_Resource_372x255.jpg"
                                  title="Take Control with SBL Built for the Realities of Your Business" alt="Take Control with SBL Built for the Realities of Your Business"
                                  class="cards__image">
                          </div>
                          <div class="cards__copy">
                              <div class="cards__type">Resource</div>
                              <div class="cards__title large">Take Control with SBL Built for the Realities of Your Business</div>
                          </div>
                      </a>

                  </div>
                  <div class="bottom-link">
                      <a href="#" class="form__field__input form__field__input--button cta">Wealth Lending Network</a>
                  </div>
              </div>
          </div>
      </section>

      <!--End Wealth Lending Network Code-->
      <footer>
          <div class="footer">
              <div class="footer__main">
                  <div class="outer-wrapper">
                      <div class="inner-wrapper">
                          <div class="logo">
                              <img src="../assets/images/BR_logo_white_footer.png"
                                  title="Broadridge Logo" alt="Broadridge Logo">
                          </div>
                          <div class="copy">Broadridge, a global Fintech leader with over $4.5 billion in revenues, helps clients get ahead of today's challenges to capitalize on what's next with communications, technology, data and intelligence solutions that help transform their businesses.</div>
                          <div class="logo">
                              <img src="../assets/images/RfN_logo_footer.png"
                                  title="Ready For Next image" alt="Ready For Next image">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="footer__bottom-nav">
                  <div class="outer-wrapper">
                      <div class="inner-wrapper">
                          <div class="footer__copyright"><p>&copy; 2021 Broadridge Financial Solutions, Inc.&nbsp;<br class="show-small"> All Rights
                              Reserved. </p>
                              <div class="footer__copyright__links">
                                  <a href="https://www.broadridge.com/legal/privacy-statement-english" target="_self">Privacy Statement</a>
                                  <a href="https://www.broadridge.com/legal/terms-of-use" target="_self">Terms of Use &amp; Linking Policy</a>
                                  <a href="https://www.broadridge.com/legal/privacy-statement-english#cookies" target="_self">Cookie Policy</a>
                                  <a href="https://www.broadridge.com/legal/legal-statements" target="_self">Legal Statements</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>

    </div>

</template>

<style lang="scss">
  @import url('../assets/css/main.css');
  @import url('../assets/css/cp_style.css');
  @import url('../assets/css/wealthlendingnetwork.css');
</style>

<script>

import dayjs from "dayjs";
import TBBKTerms from "../components/TBBKTerms.vue";
import NPTerms from "../components/NPTerms.vue";
import { CUSTODIANS, LENDER_BANKS, OPTIONS, LINK_BR_SBL_OPTIMIZER, LINK_FEATURE_BANK_BANCORP } from "./constants.ts";
import utc from "dayjs/plugin/utc";


export default {
  name: 'Home',
  data() {
    dayjs.extend(utc)
    return {
      isLoading: false,
      formConfirmation: false,
      termsConfirmation: false,
      formSubmission: {
        ReferralDate: dayjs(new Date()).format('YYYY-MM-DDTHH:mmZ'),
        Message: 'I would like a representative of The Bancorp to contact me to discuss arranging a Securities-Based Loan for my client'
      },
      formErrors: [],
      submissionError: false,
      showModal: false
    };
  },
  created() {
    this.CUSTODIANS = CUSTODIANS;
    this.LENDER_BANKS = LENDER_BANKS;
    this.OPTIONS = OPTIONS;
    this.custodiansWithMultipleLenderBanks = OPTIONS.filter(o => o.lenderBanks.length > 1).map(o => o.custodian);
    this.LINK_FEATURE_BANK_BANCORP = LINK_FEATURE_BANK_BANCORP;
    this.LINK_BR_SBL_OPTIMIZER = LINK_BR_SBL_OPTIMIZER;
  },
  components: {
    TBBKTerms,
    NPTerms
  },
  computed: {
    apiUrl()
    {
      return process.env.VUE_APP_API_ENDPOINT;
    }
  },
  methods: {
    submitForm() {

      // Set the Is Loading flag.
      this.isLoading = true;
      this.submissionError = false;

      // Build the API endpoint URL.
      let endpoint = `${this.apiUrl}submit-request`;

      if (!this.custodiansWithMultipleLenderBanks.includes(this.formSubmission.Custodian)) {
        delete this.formSubmission.LenderBank;
      }

      this.$http.post(endpoint, this.formSubmission)
        .then((res) => {
          // All good. Redirect to Confirmation.
          localStorage.setItem('submissionResp', JSON.stringify(res.data));
          let confirmationText =
            OPTIONS.map(o => o.custodian).includes(this.formSubmission.Custodian) ?
              `Your referral has been submitted successfully.
              We'll be in touch soon.`
              :
              `Unfortunately, our lending partners
              currently lend against securities held by Charles Schwab, Fidelity, TD Bank, Pershing, Pershing and State Street. 
              We continue to add lending banks to the network and will soon support a wider range of custodians. 
              We will let you know when we can support you.`
          this.$router.push({ name: 'Confirmation', params: { text: confirmationText } });
        })
        .catch(res => {
          this.formErrors = res.data;
          this.submissionError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>
