<script>
export default {
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" role="dialog">
          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <button type="button" class="btn-close" @click="close" aria-label="Close modal">
              ×
            </button>
          </div>
          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <div class="footer-content">
                <button type="button" class="modal-default-button cta" @click="close" aria-label="Close modal">
                  OK
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.btn-close {
  background: transparent;
  font-size: 2rem;
  color: #000;
  border: 0;
  opacity: .5;
  cursor: pointer;
  align-self: start;
  position: absolute;
  top: 20px;
  right: 20px;
}

.btn-close:hover {
  opacity: 1;
}

.modal-body {
  margin: 20px 0;
}

.footer-content {
  display: flex;
  justify-content: flex-end;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity .3s ease;
}
</style>