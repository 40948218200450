<script>
import Modal from './Modal.vue';

export default {
  name: 'TBBKTerms',
  methods: {
    close() {
      this.$emit('close');
    },
  },
  components: {
    Modal
  }
};
</script>

<template>
  <Modal @close="close">
    <span slot="header">Terms & Conditions</span>
    <span slot="body">
      <div id="TBBK_terms">
        <p>
          Minimum requested amount must be $100,000.00 or such greater amount as may be prescribed from time
          to time pursuant to 12 C.F.R. Section 1026.3(b) of Regulation Z issued by the Consumer Financial
          Protection Bureau as the minimum threshold necessary to qualify the Line of Credit as an exempt
          consumer credit transaction thereunder.
        </p>
        <ol>
          <li>
            Securities-based borrowing has special risks and may not be suitable for everyone.
            Consult a financial advisor about any associated risks. Consult a tax advisor for
            tax-related matters and an attorney for legal matters, The Bancorp Bank (“Bank”)
            does not provide financial, tax or legal advice.
          </li>
          <li>
            Policy must be in effect for at least 12 months at the time of credit application. 
            Line of credit is contingent on life insurance policy remaining in good standing. 
            The insurance policy owner must be the borrower. 
            Insurance policy must be issued by one of the following approved insurance providers 
            to be eligible as IBLOC collateral: Guardian, MassMutual, Northwestern Mutual, NY Life, 
            John Hancock, Penn Mutual.
          </li>
          <li>
            Subject to credit approval and underwriting.
          </li>
          <li>
            Collateral Lending Value is an amount equal to the sum of the then cash surrender
            value of the policy to which the pledgor is entitled, multiplied by such percentage
            as the Bank may determine in its discretion, not to exceed ninety-five percent (95%).
          </li>
        </ol>
      </div>
    </span>
  </Modal>
</template>

<style>
#TBBK_terms {
  font-size: 15px;
  line-height: 1.4;
  font-style: italic;
}

#TBBK_terms ol li:not(:last-child) {
  margin-bottom: 16px;
}
</style>