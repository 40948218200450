export const CUSTODIANS = {
  CHARLES_SCHWAB: "Charles Schwab",
  TD_BANK: "TD Bank",
  PERSHING: "Pershing",
  FIDELITY: "Fidelity",
  OTHER: "Other",
};

export const LENDER_BANKS = {
  NORTH_PRIVATE_BANK: "42 North Private Bank",
  THE_BANCORP: "The Bancorp",
};

export const OPTIONS = [
  {
    custodian: CUSTODIANS.CHARLES_SCHWAB,
    lenderBanks: [LENDER_BANKS.THE_BANCORP]
  },
  {
    custodian: CUSTODIANS.TD_BANK,
    lenderBanks: [LENDER_BANKS.THE_BANCORP]
  },
  {
    custodian: CUSTODIANS.PERSHING,
    lenderBanks: [LENDER_BANKS.NORTH_PRIVATE_BANK]
  },
  {
    custodian: CUSTODIANS.FIDELITY,
    lenderBanks: [LENDER_BANKS.NORTH_PRIVATE_BANK]
  }
];

export const LINK_BR_SBL_OPTIMIZER = "https://www.broadridge.com/resource/wealth-management/broadridge-sbl-practice-optimizer"
export const LINK_FEATURE_BANK_BANCORP = "https://institutionalbanking.thebancorp.com/lending-services/sbloc"
